.footer{
    background: #F7F7F7;
}

.footer-logo{
    width: 30px;
    height: 30px;
}

.footer li{
    list-style-type: none;
}

.footer li a{
    color: black;
}